<template>
  <div class="bg-white shadow-lg rounded-md px-5 py-4">
    <h2 class="text-2xl font-bold mb-5">Sign In.</h2>
    <p class="mb-5 text-sm">Sign in you your account to manage disputes</p>
    <div v-if="message !== '' && error" class="mb-3">
      <span
        :class="`mb-0 block text-xs font-semibold ${
          error ? 'text-red-500' : 'text-green-500'
        }`"
        >{{ message }}
      </span>
      <p class="my-0">
        Don't get mail?
        <b class="text-primary cursor-pointer" @click="resendVerification"
          >Resend mail</b
        >
      </p>
    </div>

    <a-form-model
      ref="signinForm"
      :rules="rules"
      layout="vertical"
      :model="form"
    >
      <a-form-model-item label="Email" prop="email">
        <a-input
          v-model="form.email"
          type="email"
          :disabled="signing_in"
          placeholder="Email"
        />
      </a-form-model-item>
      <a-form-model-item label="Password" prop="password">
        <a-input-password
          v-model="form.password"
          :disabled="signing_in"
          placeholder="Enter your password"
        />
      </a-form-model-item>

      <div class="flex items-center justify-end">
        <p>
          <router-link :to="`/forget-password?email=${form.email}`"
            >Forget Password?</router-link
          >
        </p>
      </div>
      <a-form-model-item>
        <a-button
          class="w-full"
          :loading="signing_in"
          type="primary"
          @click="onSubmit"
        >
          Sign In
        </a-button>
      </a-form-model-item>

      <p>
        I don't have an account.
        <router-link to="/odr/signup">Sign Up </router-link>here.
      </p>
    </a-form-model>
  </div>
</template>
<script>
import authApi from '../../api/auth'
export default {
  name: 'SignIn',
  layout: 'odrauth',
  data() {
    return {
      error: false,
      message: '',
      signing_in: false,
      redirectLink: '',
      form: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          {
            pattern:
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: 'Enter a valid email address',
            trigger: 'blur',
          },
        ],
        password: {
          required: true,
          message: 'Enter your password',
          trigger: 'blur',
        },
      },
    }
  },
  head: {
    title: 'Sign In | Legal Naija',
  },
  created() {
    this.redirectLink = this.$route.query.redirect
  },
  methods: {
    async resendVerification() {
      this.message = ''
      const req = await authApi(this.axios).resendEmailVerification({
        email: this.form.email,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to send email',
          description: req.message,
        })
        return
      }
      this.$notification.success({
        message: 'Email sent successfully',
        description: req.message,
      })
    },
    async loginUser() {
      this.message = ''
      this.signing_in = true
      const req = await authApi(this.axios).login({
        email: this.form.email,
        password: this.form.password,
      })
      if (req.error) {
        this.error = true
        this.message = req.message
        this.signing_in = false
        return
      }
      this.error = false
      this.message = req.message
      this.signing_in = false
      this.$store.commit('auth/SET_USER', req.data.user)
      this.$store.commit('auth/SET_TOKEN', req.data.token)
      setTimeout(() => {
        if (this.redirectLink) {
          this.$router.push(this.redirectLink)
          return
        }
        this.$router.push('/odr/manage-case')
      }, 2000)
    },
    onSubmit() {
      this.$refs.signinForm.validate((valid) => {
        if (valid) {
          this.loginUser()
        } else {
          return false
        }
      })
    },
  },
}
</script>
